import React from 'react';
import './Button.css';


function Button({label, clazz, onClick}) {

  return (
      <button className={clazz} onClick={onClick}>
        {label}
      </button>
  );

};

export default Button;