import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { parseISO, format } from "date-fns";

import { LoginContext } from "../helper/LoginContext";
import config from "../config/BackendConfiguration";
import Button from '../component/Button';
import PostRequest from '../helper/PostRequest';
import { errorToast, successToast } from "../helper/ToastMaster";
import MapComponent from '../component/MapComponent';
import { LoaderContext } from "../helper/LoaderContext";

function AddAddress() {
    const navigate = useNavigate();
    const { postRequest, responsePost, headersPost, errorPost } = PostRequest();
    const { isLoggedIn, isLoading } = useContext(LoginContext);
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const [orderData, setOrderData] = useState([]);
    const [latLng, setLatLng] = useState(null);

    const street1Ref = useRef(null);
    const street2Ref = useRef(null);
    const cityRef = useRef(null);
    const stateRef = useRef(null);
    const pincodeRef = useRef(null);
    const nickNameRef = useRef(null);

    const saveAddress = async () => {

        showLoader();

        const url = config.API_HOST + config.ADD_ADDRESS_URL;
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };

        const body = {
            'userId': localStorage.getItem('userId'),
            'street1': street1Ref.current.value,
            'street2': street2Ref.current.value,
            'city': cityRef.current.value,
            'state': stateRef.current.value,
            'pincode': pincodeRef.current.value,
            'nickName': nickNameRef.current.value,
            'latitude': latLng.lat,
            'longitude': latLng.lng
        }
    
        const response = await postRequest(url, body, headers);

        if(response.status == 200) {
            successToast("Address saved!")
            hideLoader();
            navigate("/home");
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not save your address." + "\n" + response.data.errorMessage);
        }
    };

    const handlePositionChange = (newPosition) => {
        //TODO Fetch city, state
    };

    useEffect(() => {
        if (!isLoading) {
            if (isLoggedIn.state) {
                
            } else {
                navigate('/signup');
            }
        }
    }, [isLoading, isLoggedIn, navigate]);

    return (
        <div className="holder">
            <div className="container">
                <div className="header-container">
                    <h2 className="header-text">Add Address</h2>
                </div>
                <MapComponent setLatLng={setLatLng} onPositionChange={handlePositionChange} />
                <div className="form">
                    <div className="form-group">
                        <label className="input-header">Street 1: </label>
                        <input className="input-reg" type="text" placeholder="" ref={street1Ref} required />
                    </div>
                    <div className="form-group">
                        <label className="input-header">Street 2: </label>
                        <input className="input-reg" type="text" placeholder="" ref={street2Ref} required />
                    </div>
                    <div className="form-group">
                        <label className="input-header">City: </label>
                        <input className="input-reg" type="text" placeholder="" ref={cityRef} required />
                    </div>
                    <div className="form-group">
                        <label className="input-header">State: </label>
                        <input className="input-reg" type="text" placeholder="" ref={stateRef} required />
                    </div>
                    <div className="form-group">
                        <label className="input-header">Pincode: </label>
                        <input className="input-reg" type="text" ref={pincodeRef} required />
                    </div>
                    <div className="form-group">
                        <label className="input-header">NickName: </label>
                        <input className="input-reg" type="text" placeholder="Home" ref={nickNameRef} required />
                    </div>
                    <div className="header-container">
                        <Button clazz="button-unselected button-fixed-width" onClick={saveAddress} label="Save" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddAddress;