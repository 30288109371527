import React from "react";

function Terms() {


    return (
        <div className="holder">
            <div className="container">
                <div className="orders-header-container">
                    <h2 className="header-text">Terms and Conditions</h2>
                </div>
                <div className="form">
                    <p>Effective Date: 6 June, 2024</p>
                    <p>
                        Welcome to Printtt! Printtt is an initiative registered under Amicus Communications Private Limited. See more at <a href='http://www.amicus.co.in'  target="_blank" rel="noopener noreferrer">amicus.co.in</a>
                        <br/><br/>
                        These terms and conditions outline the rules and regulations for the use of our website and services. 
                        By accessing this website, we assume you accept these terms and conditions.
                    </p>
                    
                    <h4>1. Intellectual Property</h4>
                    <p>
                        Unless otherwise stated, Printtt owns the intellectual property rights for all material on this website. All intellectual property rights are reserved.
                    </p>

                    <h4>2. User Account</h4>
                    <p>
                        If you create an account on our website, you are responsible for maintaining the security of your account and for all activities that occur under the account. 
                        You must notify us immediately of any unauthorized uses of your account or any other breaches of security.
                    </p>

                    <h4>3. Orders</h4>
                    <p>
                        When you place an order with us, you are offering to purchase a product under the terms and conditions set out herein. 
                        All orders are subject to availability and confirmation of the order price.
                    </p>

                    <h4>4. Pricing and Availability</h4>
                    <p>
                        While we try to ensure that all details, descriptions, and prices appearing on this website are accurate, errors may occur. 
                        If we discover an error in the price of any goods that you have ordered, we will inform you as soon as possible and give you 
                        the option of reconfirming your order at the correct price or canceling it.
                    </p>

                    <h4>5. Delivery</h4>
                    <p>
                        Delivery times may vary depending on availability and subject to any delays resulting from postal delays or force majeure for which we will not be responsible.
                    </p>

                    <h4>6. Limitation of Liability</h4>
                    <p>
                        Printtt shall not be liable for any indirect, incidental, special, or consequential damages, including loss of profits, revenue, data, or use incurred by you or any third party, 
                        whether in an action in contract or tort, arising from your access to or use of our website.
                    </p>

                    <h4>7. Changes to These Terms</h4>
                    <p>
                        We reserve the right to make changes to these terms and conditions at any time. 
                        Your continued use of the website will signify your acceptance of any adjustment to these terms.
                    </p>

                    <h4>8. Governing Law</h4>
                    <p>
                        These terms and conditions are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction 
                        of the courts in that location.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Terms;