//Backend Configuration

const config = {
    API_HOST: "https://printtt.in/api/printtt",

    //Auth
    SIGNUP_URL: "/user/create",
    LOGIN_URL: "/auth/login",
    LOGOUT_URL: "/auth/logout",

    //Get
    GET_ORDERS_URL: "/order/list/$userId",
    GET_USER_URL: "/user/$userId",
    GET_ADDRESSES_URL: "/address/list/$userId",
    GET_ACTIVE_ORDER_URL: "/order/active/$userId",
    GET_DOCUMENTS: "/order/documents/list/$orderId",
    GET_PRICING: "/pricing/generate/$orderId",
    GET_COST: "/pricing/list",

    //Post
    CREATE_ORDER_URL: "/order/create/upload",
    CREATE_ORDER_DETAILS_URL: "/order/create/details",
    UPDATE_USER_URL: "/user/update",
    ADD_ADDRESS_URL: "/address/create",
    DELETE_ADDRESS_URL: "/address/delete",
    INITIATE_PAYMENT_URL: "/payment/pg/initiate/$orderId"
}

export default config;
