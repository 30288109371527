import React from "react";

function Cancellation() {


    return (
        <div className="holder">
            <div className="container">
                <div className="orders-header-container">
                    <h2 className="header-text">Cancellation and Refund Policy</h2>
                </div>
                <div className="form">
                    <p>Effective Date: 6 June, 2024</p>
                    <p>At Printtt, we strive to provide the best service to our customers. 
                        However, we understand that sometimes plans change. Below is our policy on cancellations and refunds.
                    </p>
                    
                    <h4>1. Order Cancellation</h4>
                    <p>
                        <ul>
                            <li>Before Processing: You may cancel your order before it is processed. To cancel your order, please contact our customer service team as soon as possible.</li>
                            <li>After Processing: Once your order has been processed, it cannot be canceled.</li>
                        </ul>
                    </p>

                    <h4>2. Refunds</h4>
                    <p>
                        <ul>
                            <li>Returns: We do not accept returns, however, refunds will be processed as per the refund policy below.</li>
                            <li>Eligibility: Refunds are available for orders that are canceled before processing or if the product is found to be defective or damaged upon delivery.</li>
                            <li>Process: To request a refund, please contact our customer service team with your order details and reason for the refund request. If the refund is approved, it will be processed within 7 business days, and the funds will be credited to the original method of payment within 10 business days</li>
                            <li>Partial Refunds: In some cases, we may offer partial refunds for orders that have been partially fulfilled or if only a portion of the order is found to be defective.</li>
                        </ul>
                    </p>

                    <h4>3. Changes to This Policy</h4>
                    <p>
                        We may update this Cancellation and Refund Policy from time to time. We will notify you of any changes by updating the "Effective Date" at the top of this policy.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Cancellation;