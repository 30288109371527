import { toast } from 'react-toastify';


export const defaultToast = (msg) => {
    toast(msg);
}

export const successToast = (msg) => {
    toast.success(msg);
}

export const errorToast = (msg) => {
    toast.error(msg);
} 

export const clearToast = () => {
    toast.dismiss();
} 