import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { parseISO, format } from "date-fns";

import Button from "../component/Button";
import config from "../config/BackendConfiguration";
import { LoginContext } from "../helper/LoginContext";
import GetRequest from '../helper/GetRequest'
import PostRequest from '../helper/PostRequest'
import { errorToast, successToast, defaultToast, clearToast } from "../helper/ToastMaster";
import deleteIcon from "../resources/deleteIcon.png";

function UserHome() {

    const { isLoggedIn, isLoading, setFirstName, logout } = useContext(LoginContext);
    const { getRequest, responseGet, headersGet, errorGet } = GetRequest();
    const { postRequest, responsePost, headersPost, errorPost } = PostRequest();
    const navigate = useNavigate();

    const emailRef = useRef(null);
    const mobileRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const dobRef = useRef(null);
    const genderRef = useRef(null);

    const [tab, setTab] = useState("profile"); // profile, address
    const [addresses, setAddresses] = useState([]);

    const switchTab = (tabToSwitch) => {setTab(tabToSwitch);}

    const updateAction = async () => {
        const url = config.API_HOST + config.UPDATE_USER_URL; 
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };

        const body = {
            "userId": localStorage.getItem('userId'),
            "email": emailRef.current.value,
            "mobile": mobileRef.current.value,
            "firstName": firstNameRef.current.value,
            "lastName": lastNameRef.current.value,
            "dob": dobRef.current.value,
            "gender": genderRef.current.value,
        }
    
        const response = await postRequest(url, body, headers);

        if(200 == response.status) {
            successToast("Profile updated!");
            setFirstName(firstNameRef.current.value);
        } else {
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not fetch user details. " + "\n" + response.data.errorMessage);
        }
    }

    const loadDetails = async () => {
        const url = config.API_HOST + config.GET_USER_URL.replace("$userId", localStorage.getItem('userId'));;    
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
    
        const response = await getRequest(url, headers);

        if(200 == response.status) {

            emailRef.current.value = response.data.userDto.email;
            mobileRef.current.value = response.data.userDto.mobile;
            firstNameRef.current.value = response.data.userDto.firstName;
            lastNameRef.current.value = response.data.userDto.lastName;
            dobRef.current.value = format(parseISO(response.data.userDto.dob), 'yyyy-MM-dd');
            genderRef.current.value = response.data.userDto.gender;

        } else {
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not fetch user details. " + "\n" + response.data.errorMessage);        }
    }

    const fetchAddresses = async () => {

        const url = config.API_HOST + config.GET_ADDRESSES_URL.replace("$userId", localStorage.getItem('userId'));
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
    
        const response = await getRequest(url, headers);

        if(response.status == 200) {
            setAddresses(response.data.addresses);
        } else {
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not fetch your addresses. " + "\n" + response.data.errorMessage);
        }
    }

    const deleteAddress = async (addressId) => {

        const url = config.API_HOST + config.DELETE_ADDRESS_URL;
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };

        const body = {
            'id': addressId,
            'userId': localStorage.getItem('userId')
        }
    
        const response = await postRequest(url, body, headers);

        if(response.status == 200) {
            successToast("Address deleted!")
            fetchAddresses();
        } else {
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not delete your address. " + "\n" + response.data.errorMessage);
        }
    }

    const logoutAction = async () => {
        const url = config.API_HOST + config.LOGOUT_URL;
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };

        defaultToast("Logging you out ...");

        const response = await postRequest(url, {}, headers);

        clearToast();

        if(response.status == 200) {
            successToast("Logout success.")
            logout();
            navigate("/");
        } else {
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not log you out. " + "\n" + response.data.errorMessage);
        }

    }

    useEffect(() => {

        if(!isLoading){
            if(isLoggedIn.state) {

                loadDetails();

            } else {
                navigate('/signup');
            }
        }

    }, [isLoading, isLoggedIn, navigate]);

    return (
        <div className="holder">
            <div className="header-container">
                <Button clazz={ tab == "profile" ? "button-selected button-fixed-width" : "button-unselected button-fixed-width" } onClick={() => {switchTab('profile'); loadDetails();}} label="Profile" />
                <Button clazz={ tab == "address" ? "button-selected button-fixed-width" : "button-unselected button-fixed-width" } onClick={() => {switchTab('address'); fetchAddresses();}} label="Addresses" />
                <Button clazz="button-unselected button-fixed-width" onClick={logoutAction} label="Logout" />
            </div>
            {tab == "profile" && (<div className="container">
                <div className="header-container">
                    <h2 className="header-text">Profile</h2>
                </div>
                <div className="form">
                    <div className="form-group">
                        <label className = "input-header">E-Mail: </label>
                        <input className="input-reg" type="email" placeholder="john.doe@mail.com" ref={emailRef} required disabled/>
                    </div>
                    <div className={"form-group"}>
                        <label className = "input-header">Mobile: </label>
                        <input className="input-reg" type="mobile" placeholder="9876543210" ref={mobileRef} required />
                    </div>
                    <div className="form-group">
                        <label className = "input-header">First Name: </label>
                        <input className="input-reg" type="text" placeholder="John" ref={firstNameRef} required />
                    </div>
                    <div className="form-group">
                        <label className = "input-header">Last Name: </label>
                        <input className="input-reg" type="text" placeholder="Doe" ref={lastNameRef} required />
                    </div>
                    <div className="form-group">
                        <label className = "input-header">Date of Birth: </label>
                        <input className="input-reg" type="date" ref={dobRef} required />
                    </div>
                    <div className="form-group">
                        <label className = "input-header">Gender: </label>
                        <select className="input-reg" defaultValue="" ref={genderRef} required>
                            <option value="" disabled>Select an option</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="OTHER">Other</option>
                        </select>
                    </div>
                    <div className="header-container">
                        <Button clazz="button-unselected button-fixed-width" onClick={updateAction} label="Update" />
                    </div>
                </div>
            </div> )}
            { tab == "address" && (<div className="container">
                <div className="header-container">
                    <h2 className="header-text">Addresses</h2>
                </div>
                <table  style={{ display: addresses.length == 0 ? 'none' : 'inline-table' }}>
                    <thead>
                    <tr>
                        <th>Nick Name</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {addresses.map((item) => (
                        <tr key={item.id}>
                            <td>{item.nickName}</td>
                            <td>{item.street1 + ", " + item.street2}</td>
                            <td>{item.city}</td>
                            <td><img src = {deleteIcon} className="delete-icon" onClick={() => deleteAddress(item.id)}/></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="empty-container" style={{ display: addresses.length == 0 ? 'flex' : 'none' }} >You haven't added any addresses yet!</div>
                <div className="header-container">
                    <Link to="/address">
                        <Button clazz="button-unselected button-fixed-width" label="Add Address" />
                    </Link>
                </div>
            </div> )}
        </div>
    );
}

export default UserHome;