import React, { useEffect, useState } from 'react';
import './VideoSection.css'

import video from '../resources/video.mp4'

function VideoSection({children}) {


    return (
    <div className="video-container">
        <video src={video} autoPlay loop muted />
        <div className="overlay">
            {children}
        </div>
    </div>
    );
}

export default VideoSection;