import React from "react";

function Shipping() {


    return (
        <div className="holder">
            <div className="container">
                <div className="orders-header-container">
                    <h2 className="header-text">Shipping Policy</h2>
                </div>
                <div className="form">
                    <p>Effective Date: 6 June, 2024</p>
                    
                    <h4>Delivery Timelines</h4>
                    <p>
                        <ul>
                            <li>Delivery will be processed in a daily 2 slot schedule. All orders that have been placed before a specific slot will be delivered in the consecutive slot.</li>
                            <li>First slot commences at 9 AM and ends at 3 PM, and the second slot commences at 3 PM and ends at 8 PM</li>
                            <li>Orders placed outside a slot will be processed in the upcoming slot.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Shipping;