import React, { useContext, useEffect, useState, useRef }  from "react";
import { useNavigate } from "react-router-dom";
import { parseISO, format } from "date-fns";

import { LoginContext } from "../helper/LoginContext";
import config from "../config/BackendConfiguration";
import GetRequest from '../helper/GetRequest'
import PostRequest from "../helper/PostRequest";
import { errorToast } from "../helper/ToastMaster";
import Button from "../component/Button";
import {LoaderContext} from '../helper/LoaderContext'

function UserOrderDetails() {

    const navigate = useNavigate();
    const {isLoggedIn, isLoading} = useContext(LoginContext);
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const { getRequest, responseGet, headersGet, errorGet } = GetRequest();
    const { postRequest, responsePost, headersPost, errorPost } = PostRequest();

    const [activeOrder, setActiveOrder] = useState({});
    const [documents, setDocuments] = useState([]);

    const handleInputChange = (index, field, value) => {
        const newDocuments = [...documents];
        newDocuments[index][field] = value;
        setDocuments(newDocuments);
    };

    const findOrder = async () => {

        showLoader();

        const url = config.API_HOST + config.GET_ACTIVE_ORDER_URL.replace("$userId", localStorage.getItem('userId'));
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
        
        const response = await getRequest(url, headers);

        if(200 == response.status) {
            if(response.data.id == null) {
                hideLoader();
                errorToast("No active order. Please place a new order.")
                navigate("/order/upload");
            } else {
                setActiveOrder(response.data);
                hideLoader();
            }
        } else {
            hideLoader();
            errorToast("Could not find your order.");
        }
    }

    const findDocuments = async () => {

        showLoader();

        const url = config.API_HOST + config.GET_DOCUMENTS.replace("$orderId", activeOrder.id);
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
        
        const response = await getRequest(url, headers);

        if(200 == response.status) {
            setDocuments(response.data.documents);
            hideLoader();
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not retrieve your documents. " + "\n" + response.data.errorMessage);
        }
    }

    const checkout = async () => {

        showLoader();

        const url = config.API_HOST + config.CREATE_ORDER_DETAILS_URL
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };

        const body = new FormData();
        body.append("userId", localStorage.getItem('userId'));

        for(let iter = 0; iter < documents.length; iter++) {
            body.append("documents[" + iter + "].id", documents[iter].id);
            body.append("documents[" + iter + "].paperSize", documents[iter].paperSize);
            body.append("documents[" + iter + "].colour", documents[iter].colour);
            body.append("documents[" + iter + "].copies", documents[iter].copies);
        }

        const response = await postRequest(url, body, headers);

        if(200 == response.status) {
            hideLoader();
            navigate("/order/checkout");
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not submit your document details. " + "\n" + response.data.errorMessage);
        }

    }

    useEffect(() => {

        if(!isLoading){
            if(isLoggedIn.state) {
                findOrder();
            } else {
                navigate('/signup');
            }
        }

    }, [isLoading, isLoggedIn, navigate]);

    useEffect(() => {

        if(activeOrder.id != null){
            findDocuments();
        }

    }, [activeOrder]);

    return (
        <div className="holder">
            <div className="form container">
                <div className="header-container">
                    <h2 className="header-text">Order Details for {activeOrder.orderUID}</h2>
                </div>
                {documents.map((document, index) => (
                    <div className="container" key={document.id}>
                        <div className="sub-header-text">{document.documentName} (Pages: {document.pages})</div>
                        <div className="sub-header-line"></div>
                        <div className="form-group">
                            <label className = "input-header">Paper Size: </label>
                            <select className="input-reg" name="paperSize" defaultValue=""
                                onChange={(e) => handleInputChange(index, 'paperSize', e.target.value)}>
                                <option value="" disabled>Select an option</option>
                                <option value="A3">A3</option>
                                <option value="A4">A4</option>                                       
                            </select>                            
                        </div>
                        <div className="form-group">
                            <label className = "input-header">Colour: </label>
                            <select className="input-reg" name="colour" defaultValue="" 
                                onChange={(e) => handleInputChange(index, 'colour', e.target.value)}>
                                <option value="" disabled>Select an option</option>
                                <option value="MONO">Black & White</option>
                                <option value="COLOUR">Colour</option>
                            </select>                          
                        </div>
                        <div className="form-group">
                            <label className = "input-header">Copies: </label>
                            <input type="number" className="input-reg" name="copies" defaultValue=""
                                onChange={(e) => handleInputChange(index, 'copies', e.target.value)}/>
                        </div>
                    </div>
                ))}
                <div className="submit-container">
                    <Button clazz="button-selected button-fixed-width" onClick={checkout} label="Checkout" />
                </div>
            </div>     
        </div>
    );
}

export default UserOrderDetails;