import React, { useEffect, useState, useContext } from 'react';
import {Link} from 'react-router-dom'

import Button from '../component/Button'
import { LoginContext } from '../helper/LoginContext';

function Home() {

    const { isLoggedIn } = useContext(LoginContext);
    
    return (
    <div className="holder">
        <div className='home-section-1'>
            <div className='home-header'>
                <div className='home-header-regular'>Order.</div>
                <div className='home-header-project'>Printtt.</div>
                <div className='home-header-regular'>Deliver.</div>
            </div>
            <Link to={isLoggedIn.state ? "/order/upload" : "/signup"}>
                <Button clazz="home-button-outline button-fixed-width" label="Order Now" />
            </Link>
            <Link to="/signup" style={{ display: isLoggedIn.state ? 'none' : 'block' }}>
                <Button clazz="home-button-fill button-fixed-width" label="Sign Up" />
            </Link>
        </div>
        <div className='home-section-2'>
            <div className='home-section-2-container'>
                <h2>Why Printtt?</h2>
                <p className='home-p'>
                Experience seamless printing with our top-notch printout delivery service! We bring high-quality prints straight to your doorstep, ensuring convenience and security. From documents to photos, get your prints delivered with exceptional care. Say goodbye to printing hassles and hello to effortless excellence!
                </p>
            </div>
        </div>
        <div className='footer-section'>
            <div className='footer-link'>
                <Link to="/contact">
                    Help and Support
                </Link>
                <Link to="/policy/tnc">
                    Terms and Conditions
                </Link>
                <Link to="/policy/privacy">
                    Privacy Policy
                </Link>
                <Link to="/policy/cancellation">
                    Cancellation and Refund Policy
                </Link>
                <Link to="/policy/shipping">
                    Shipping Policy
                </Link>
            </div>
            <div className='amicus'>
                Printtt is an initiative registered under Amicus Communications Pvt. Ltd. <br />
                Amicus is an authorised distributor of Printers, Security cameras, Video Door Phones and much more. <br />
                See more at <a href='http://www.amicus.co.in'  target="_blank" rel="noopener noreferrer">amicus.co.in</a>
            </div>

        </div>
    </div>
    );
}

export default Home;