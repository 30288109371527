import { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import './App.css';
import './pages/styles.css';
import './pages/mobile-styles.css';
import VideoSection from './component/VideoSection';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import UserHome from './pages/UserHome';
import UserProfile from './pages/UserProfile'
import Navbar from './component/Navbar';
import UserOrder from './pages/UserOrder';
import UserOrderDetails from './pages/UserOrderDetails';
import UserOrderCheckout from './pages/UserOrderCheckout';
import AddAddress from './pages/AddAddress'
import { LoginProvider } from './helper/LoginContext';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Cancellation from './pages/Cancellation';
import { LoaderProvider } from './helper/LoaderContext';
import Loader from './component/Loader';
import Pricing from './pages/Pricing';
import Shipping from './pages/Shipping';

function AnimatedRoutes() {

    const location = useLocation();
    const nodeRef = useRef(null);

    return (
          <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames="fade"
            nodeRef={nodeRef}
          >
            <Routes location={location}>
                <Route path="/" exact element={<Home />} />
                <Route path="/signup" exact element={<SignUp />} />
                <Route path="/home" exact element={<UserHome />} />
                <Route path="/profile" exact element={<UserProfile />} />
                <Route path="/address" exact element={<AddAddress />} />
                <Route path="/order/upload" exact element={<UserOrder />} />
                <Route path="/order/details" exact element={<UserOrderDetails />} />
                <Route path="/order/checkout" exact element={<UserOrderCheckout />} />
                <Route path="/contact" exact element={<Contact />} />
                <Route path="/pricing" exact element={<Pricing />} />
                <Route path="/policy/tnc" exact element={<Terms />} />
                <Route path="/policy/privacy" exact element={<Privacy />} />
                <Route path="/policy/cancellation" exact element={<Cancellation />} />
                <Route path="/policy/shipping" exact element={<Shipping />} />
            </Routes>
          </CSSTransition>
          </TransitionGroup>
    )
}

function App() {

  return (
    <div className="App">
          <LoginProvider>
            <LoaderProvider>
              <Loader />
              <Router>
                  <VideoSection>
                    <Navbar />
                      <div className="content-container">
                            <AnimatedRoutes />
                      </div>
                  </VideoSection>
                  <ToastContainer/>
              </Router>
            </LoaderProvider>
          </LoginProvider>
    </div>
  );
}

export default App;
