import React, { useContext, useEffect, useState, useRef }  from "react";
import { Link, useNavigate } from "react-router-dom";
import { parseISO, format } from "date-fns";

import { LoginContext } from "../helper/LoginContext";
import config from "../config/BackendConfiguration";
import GetRequest from '../helper/GetRequest'
import PostRequest from "../helper/PostRequest";
import { errorToast } from "../helper/ToastMaster";
import Button from "../component/Button";
import {LoaderContext} from '../helper/LoaderContext'

function UserOrder() {

    const navigate = useNavigate();
    const [addressOptions, setAddressOptions] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFileText, setSelectedFileText] = useState(null);
    const {isLoggedIn, isLoading} = useContext(LoginContext);
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const { getRequest, responseGet, headersGet, errorGet } = GetRequest();
    const { postRequest, responsePost, headersPost, errorPost } = PostRequest();

    const fileInputRef = useRef(null);
    const addressIdRef = useRef(null);

    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    useEffect(() => {
        if(selectedFiles.length == 0)
            setSelectedFileText("You haven't selected any files yet!");
        else
            setSelectedFileText("Awesome! You've selected " + selectedFiles.length + " file" + (selectedFiles.length > 1 ? "s" : ""));
      }, [selectedFiles]);

    
    const loadAddressOptions = async () => {

        showLoader();

        const url = config.API_HOST + config.GET_ADDRESSES_URL.replace("$userId", localStorage.getItem('userId'));
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
    
        const response = await getRequest(url, headers);

        var addOpts = [];



        if(response.status == 200) {
            for(let i in response.data.addresses) {
                var addOptsItem = {
                    'key': response.data.addresses[i].id,
                    'value': response.data.addresses[i].id,
                    'label': response.data.addresses[i].nickName
                }
    
                addOpts.push(addOptsItem);
            }

            setAddressOptions(addOpts);
            hideLoader();

        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not load your addresses. " + "\n" + response.data.errorMessage);
        }

    }

    const createOrder = async () => {

        showLoader();

        const url = config.API_HOST + config.CREATE_ORDER_URL;
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
    
        const body = new FormData();
        body.append("userId", localStorage.getItem('userId'));
        body.append("addressId", addressIdRef.current.value);
        for(let iter = 0; iter < selectedFiles.length; ++iter) {
            body.append(`documents[${iter}].document`, selectedFiles[iter]);
        }
        
        const response = await postRequest(url, body, headers);

        if(200 == response.status) {
            hideLoader();
            navigate("/order/details")
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not place your order " + "\n" + response.data.errorMessage);
        }
    }

    useEffect(() => {

        if(!isLoading){
            if(isLoggedIn.state) {
                loadAddressOptions();
            } else {
                navigate('/signup');
            }
        }

    }, [isLoading, isLoggedIn, navigate]);

    return (
        <div className="holder">
            <div className="form container">
                <div className="header-container">
                    <h2 className="header-text">New Order</h2>
                </div>
                <div className="form-group">
                    <label className = "input-header">Delivery Address: </label>
                    <select className="input-reg" defaultValue="" ref={addressIdRef} required>
                        <option value="" disabled>Select an option</option>
                        {addressOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>    
                    <Link to="/address">
                        <Button clazz="button-unselected button-fixed-width-small" label="+"></Button>
                    </Link>
                </div>
                <div className="file-upload-container">
                    <Button clazz="button-unselected button-fixed-width" onClick={handleFileClick} label="Select files" />
                    <input type="file" id="file-input" ref={fileInputRef} multiple onChange={handleFileChange} />
                </div>
                <div className="selected-file-container">
                    {selectedFileText}
                </div>
                <div className="submit-container">
                    <Button clazz="button-selected button-fixed-width" onClick={createOrder} label="Next" />
                </div>
            </div>     
        </div>
    );
}

export default UserOrder;