import React, { useContext, useEffect, useState }  from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { parseISO, format } from "date-fns";

import { LoginContext } from "../helper/LoginContext";
import config from "../config/BackendConfiguration";
import Button from '../component/Button';
import GetRequest from '../helper/GetRequest'
import PostRequest from '../helper/PostRequest'
import { defaultToast, errorToast } from "../helper/ToastMaster";
import {LoaderContext} from '../helper/LoaderContext';

function UserOrderCheckout() {

    const navigate = useNavigate();
    const { getRequest, responseGet, headersGet, errorGet } = GetRequest();
    const { postRequest, responsePost, headersPost, errorPost } = PostRequest();
    const {isLoggedIn, isLoading} = useContext(LoginContext);
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const [activeOrder, setActiveOrder] = useState({});
    const [pricing, setPricing] = useState([]);
    const [grandTotal, setGrandTotal] = useState([]);

    const findOrder = async () => {

        showLoader();

        const url = config.API_HOST + config.GET_ACTIVE_ORDER_URL.replace("$userId", localStorage.getItem('userId'));
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
        
        const response = await getRequest(url, headers);

        if(200 == response.status) {
            if(response.data.id == null) {
                hideLoader();
                errorToast("No active order. Please place a new order.")
                navigate("/order/upload");
            } else {
                setActiveOrder(response.data);
                hideLoader();
            }
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not find your order. " + "\n" + response.data.errorMessage);
        }
    }

    const getPricing = async () => {
        showLoader();
        const url = config.API_HOST + config.GET_PRICING.replace("$orderId", activeOrder.id);
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
        
        const response = await getRequest(url, headers);

        if(200 == response.status) {
            setPricing(response.data.pricingComponents);
            setGrandTotal(response.data.grandTotal);
            hideLoader();
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not retrieve your pricing. " + "\n" + response.data.errorMessage);
        }
    }

    const makePayment = async () => {
        showLoader();
        const url = config.API_HOST + config.INITIATE_PAYMENT_URL.replace("$orderId", activeOrder.id);
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
        
        const response = await getRequest(url, headers);

        if(200 == response.status) {
            hideLoader();
            var paymentUrl = response.data.data.instrumentResponse.redirectInfo.url;
            console.log(paymentUrl);
            window.location.href = paymentUrl;

        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not redirect to payment gateway. " + "\n" + response.data.errorMessage);
        }
    }

    useEffect(() => {

        if(!isLoading){
            if(isLoggedIn.state) {
                findOrder();
            } else {
                navigate('/signup');
            }
        }

    }, [isLoading, isLoggedIn, navigate]);

    useEffect(() => {

        if(activeOrder.id != null){
            getPricing();
        }

    }, [activeOrder]);

    return (
        <div className="holder">
            <div className="container">
                <div className="header-container">
                    <h2 className="header-text">Checkout</h2>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Component</th>
                        <th>Base Fare</th>
                        <th>Quantity</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pricing
                        .map((item) => (
                            <tr key={item.id}>
                                <td>
                                    {item.componentTypeReadable}
                                    <br />
                                    <div className="item-description-container">{item.componentDescription}</div>
                                </td>
                                <td>{item.basePrice !== 0 ? `₹${item.basePrice}` : ''}</td>
                                <td>{item.quantity !== 0 ? item.quantity : ''}</td>
                                <td>{`₹${item.total}`}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr className="grand-total">
                            <td colSpan="3">
                                Grand Total
                            </td>
                            <td>{`₹${grandTotal}`}</td>
                        </tr>
                    </tfoot>
                </table>
                <div className="submit-container">
                    <Button clazz="button-selected button-fixed-width" onClick={makePayment} label="MAKE PAYMENT" />
                </div>
            </div>
        </div>
    );
}

export default UserOrderCheckout;