import React, { useContext, useEffect, useState }  from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { parseISO, format } from "date-fns";

import { LoginContext } from "../helper/LoginContext";
import config from "../config/BackendConfiguration";
import Button from '../component/Button';
import GetRequest from '../helper/GetRequest'
import { errorToast } from "../helper/ToastMaster";
import { LoaderContext } from "../helper/LoaderContext";

function UserHome() {

    const navigate = useNavigate();
    const { getRequest, responseGet, headersGet, errorGet } = GetRequest();
    const {isLoggedIn, isLoading} = useContext(LoginContext);
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const [orderData, setOrderData] = useState([]);

    const fetchOrders = async () => {

        const url = config.API_HOST + config.GET_ORDERS_URL.replace("$userId", localStorage.getItem('userId'));    
        const headers = { 
            'user': localStorage.getItem('userId'),
            'token': localStorage.getItem('authToken')
        };
    
        const response = await getRequest(url, headers);

        if(200 == response.status) {
            for(var iter in response.data.orders) {
                const orderDate = parseISO(response.data.orders[iter].orderDate);
                const fOrderDate = format(orderDate, "EEEE, MMMM do, yyyy h:mm:ss a");
                response.data.orders[iter].orderDate = fOrderDate;
            }
            setOrderData(response.data.orders);
        } else {
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not fetch previous orders! " + "\n" + response.data.errorMessage);
        }
    }

    useEffect(() => {

        if(!isLoading){
            if(isLoggedIn.state) {

                fetchOrders();

            } else {
                navigate('/signup');
            }
        }

    }, [isLoading, isLoggedIn, navigate]);

    return (
        <div className="holder">
            <div className="header-container">
                <Link to="/order/upload">
                    <Button clazz="button-unselected button-fixed-width" label="New Order" />
                </Link>
            </div>
            <div className="container">

                <div className="orders-header-container">
                    <h2 className="header-text">Past Orders</h2>
                </div>
                <table  style={{ display: orderData.length == 0 ? 'none' : 'inline-table' }}>
                    <thead>
                    <tr>
                        <th>OUID</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderData.map((item) => (
                        <tr key={item.id}>
                        <td>{item.orderUID}</td>
                        <td>{item.orderStatus}</td>
                        <td>{item.orderDate}</td>
                        <td>{item.address.nickName}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="empty-container" style={{ display: orderData.length == 0 ? 'flex' : 'none' }} >Whoops! You do not have any past orders.</div>
            </div>
        </div>
    );
}

export default UserHome;