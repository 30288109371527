import { useState } from 'react';
import axios from 'axios';

const PostRequest = () => {
  const [responsePost, setResponsePost] = useState(null);
  const [errorPost, setErrorPost] = useState(null);

  const postRequest = async (url, body = {}, headers = {}) => {
    setErrorPost(null); // Reset error state before making the request
    try {
      const res = await axios.post(url, body, { headers });
      setResponsePost(res.data);
      return { status: res.status, data: res.data, headers: res.headers, error: null };
    } catch (err) {
      if (err.response) {
        setErrorPost(`Error: ${err.response.data.message}`);
        return { status: err.response.status, data: err.response.data, headers: null, error: err.response.data.message };
      } else if (err.request) {
        setErrorPost('Error: No response received from server');
        return { status: null, data: null, headers: null, error: 'No response received from server' };
      } else {
        setErrorPost(`Error: ${err.message}`);
        return { status: null, data: null, headers: null, error: err.message };
      }
    }
  };

  return { postRequest, responsePost, errorPost };
};

export default PostRequest;