import React from "react";

function Contact() {


    return (
        <div className="holder">
            <div className="container">
                <div className="orders-header-container">
                    <h2 className="header-text">Contact Us</h2>
                </div>
                <div className="form">
                    <p>We may not be available at all times, but we'll get back to you as soon as possible!</p><br />
                    <p>Write to us at printtt.in@gmail.com</p><br />
                    <p>Call us on +91 8547-716-540</p><br />
                </div>
            </div>
        </div>
    );
}

export default Contact;