import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';

import './Navbar.css';
import { LoginContext } from '../helper/LoginContext';

import logo from '../resources/printtt_logo_light.png'
import signup from '../resources/signup.png'
import expand from '../resources/expand.png'

function Navbar() {

    const { isLoggedIn } = useContext(LoginContext);

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {setShowMenu(!showMenu);}

    return(
        <div>
            <nav className="nav-container">
                <div className='logo-img'>
                    <Link to="/">
                        <img src={logo} className="logo-img" />
                    </Link>
                </div>
                <div className='nav-links'>
                    <ul>
                        <li className='mobile-hide'>
                            <div className="link-display-container">
                                <Link className='custom-link' to="/">Home</Link>
                            </div>
                        </li>
                        <li className='mobile-hide'>
                            <div className="link-display-container">
                                <Link className='custom-link' to={ isLoggedIn.state ? "/home" : "/signup" }>Orders</Link>
                            </div>
                        </li>
                        <li className='mobile-hide'>
                            <div className="link-display-container">
                                <Link className='custom-link' to={ isLoggedIn.state ? "/profile" : "/signup" }>Profile</Link>
                            </div>
                        </li>
                        <li className='mobile-hide'>
                            <div className="link-display-container">
                                <Link className='custom-link' to="/pricing">Pricing</Link>
                            </div>
                        </li>
                        <li className='mobile-hide'>
                            <div className="link-display-container">
                                <Link className='custom-link' to="/contact">Support</Link>
                            </div>
                        </li>
                        <li>
                            <div className="name-display-container" style={{ display: isLoggedIn.state ? 'block' : 'none' }}>
                                <div className='welcome-display'>Hello,</div> 
                                <div className='name-display'>{isLoggedIn.firstName}</div>
                            </div>
                        </li>
                        { !isLoggedIn.state &&
                        (<li>
                            <Link to="/signUp">
                                <img src={signup} className="signup-img" />
                            </Link>
                        </li>)}
                        <li className='mobile-show'>
                            <div>
                                <img src={expand} className="expand-img" onClick={toggleMenu} />
                            </div>
                        </li>
                    </ul>

                </div>
        </nav>
        <nav className={ showMenu ? 'menu-open' : 'menu-closed'} onClick={toggleMenu}>
            <ul>
                <div className="link-display-container menu-item">
                    <Link className='custom-link' to="/">Home</Link>
                </div>
            </ul>
            <ul>
                <div className="link-display-container menu-item">
                    <Link className='custom-link' to={ isLoggedIn.state ? "/home" : "/signup" }>Orders</Link>
                </div>
            </ul>
            <ul>
                <div className="link-display-container menu-item">
                    <Link className='custom-link' to={ isLoggedIn.state ? "/profile" : "/signup" }>Profile</Link>
                </div>
            </ul>
            <ul>
                <div className="link-display-container menu-item">
                    <Link className='custom-link' to="/pricing">Pricing</Link>
                </div>
            </ul>
            <ul>
                <div className="link-display-container menu-item">
                    <Link className='custom-link' to="/contact">Support</Link>
                </div>
            </ul>
        </nav>
    </div>
    );
}

export default Navbar;