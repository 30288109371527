import { useState, useContext } from 'react';
import axios from 'axios';
import { LoginContext } from './LoginContext';
import { useNavigate } from "react-router-dom";
import { defaultToast } from './ToastMaster';

const GetRequest = () => {
  const [responseGet, setResponseGet] = useState(null);
  const [errorGet, setErrorGet] = useState(null);
  const {logout} = useContext(LoginContext);
  const navigate = useNavigate();

  const getRequest = async (url, headers = {}) => {
    setErrorGet(null); // Reset error state before making the request
    try {
      const res = await axios.get(url, { headers });
      setResponseGet(res.data);
      return { status: res.status, data: res.data, headers: res.headers, error: null };
    } catch (err) {
      if (err.response) {
        
        //Auth
        if(err.response.status == 401) {
          logout();
          defaultToast("Session expired. Please login again.")
          navigate("/signup");
          return {data: {errorCode: "SESSION_EXPIRED", errorMessage: "Session Expired"}};
        }

        setErrorGet(`Error: ${err.response.data.message}`);
        return { status: err.response.status, data: null, headers: null, error: err.response.data.message };
      } else if (err.request) {
        setErrorGet('Error: No response received from server');
        return { status: null, data: null, headers: null, error: 'No response received from server' };
      } else {
        setErrorGet(`Error: ${err.message}`);
        return { status: null, data: null, headers: null, error: err.message };
      }
    }
  };

  return { getRequest, responseGet, errorGet };
};

export default GetRequest;