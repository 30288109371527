import React, { useContext } from 'react';
import './Loader.css';
import { LoaderContext } from '../helper/LoaderContext';

const Loader = () => {
  const { loading } = useContext(LoaderContext);

  return (
    loading && (
      <div className="loader-overlay">
        <div className="loader-spinner"></div>
      </div>
    )
  );
};

export default Loader;