import React, { createContext, useState, useEffect } from 'react';
export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {

  const [isLoggedIn, setIsLoggedIn] = useState({'state': false});
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {

    const userIdScope = localStorage.getItem('userId');
    const authTokenScope = localStorage.getItem('authToken');
    const firstNameScope = localStorage.getItem('firstName');
    
    const loggedInStatus = (userIdScope != undefined && authTokenScope != undefined && firstNameScope != undefined);

    if(loggedInStatus) {
        setIsLoggedIn(
          {
            'state': true,
            'userId': userIdScope,
            'authToken': authTokenScope,
            'firstName': firstNameScope
          }
        );
    }

    setIsLoading(false);

  }, []);

  const login = (userIdScope, authTokenScope, firstNameScope) => {

    localStorage.setItem('userId', userIdScope);
    localStorage.setItem('authToken', authTokenScope);
    localStorage.setItem('firstName', firstNameScope);
    
    setIsLoggedIn(
      {
        'state': true,
        'userId': userIdScope,
        'authToken': authTokenScope,
        'firstName': firstNameScope
      }
    );
  

  };

  const logout = () => {

    setIsLoggedIn({'state': false});
    localStorage.removeItem('userId');
    localStorage.removeItem('authToken');
    localStorage.removeItem('firstName');  
  };

  const setFirstName = (newFirstName) => {
    localStorage.setItem('firstName', newFirstName);
    setIsLoggedIn((prevState) => ({
      ...prevState,
      firstName: newFirstName,
    }));
  };

  return (
    <LoginContext.Provider value={{ isLoggedIn, isLoading, login, logout, setFirstName }}>
      {children}
    </LoginContext.Provider>
  );
};