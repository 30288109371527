import React, { useState, useContext, useEffect } from "react";
import config from "../config/BackendConfiguration";
import GetRequest from '../helper/GetRequest'
import { errorToast } from "../helper/ToastMaster";
import {LoaderContext} from '../helper/LoaderContext'

function Pricing() {

    const [costData, setCostData] = useState([]);
    const [discountData, setDiscountData] = useState([]);
    const [noteData, setNoteData] = useState([]);

    const { getRequest, responseGet, headersGet, errorGet } = GetRequest();
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const fetchCost = async () => {

        showLoader();

        const url = config.API_HOST + config.GET_COST;    
    
        const response = await getRequest(url, {});

        if(200 == response.status) {
            setCostData(response.data.costs);
            setDiscountData(response.data.discounts);
            setNoteData(response.data.note);
            hideLoader();
        } else {
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Could not fetch pricing! " + "\n" + response.data.errorMessage);
            hideLoader();
        }
    }

    useEffect(() => {

        fetchCost();

    },[]);

    return (
        <div className="holder">
            <div className="container">
                <div className="orders-header-container">
                    <h2 className="header-text">Pricing</h2>
                </div>
                <div className="form">
                <h4>Cost</h4>
                <table >
                    <thead>
                    <tr>
                        <th>S No</th>
                        <th>Paper Size</th>
                        <th>Colour</th>
                        <th>Unit Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {costData.map((item, index) => (
                        <tr key={item.id}>
                        <td>{index}</td>
                        <td>{item.paperSize}</td>
                        <td>{item.colourReadable}</td>
                        <td>{`₹${item.price}`}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <h4>Discounts</h4>
                <ul>
                    {discountData.map((item) => (
                        <li>{item}</li>
                    ))}
                </ul>
                <h4>Note:</h4>
                <ul>
                    {noteData.map((item) => (
                        <li>{item}</li>
                    ))}
                </ul>
                </div>
            </div>
        </div>
    );
}

export default Pricing;