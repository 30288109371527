import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for marker icon issue with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

function LocationMarker({ setPosition }) {
  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
    },
    locationfound(e) {
      map.flyTo(e.latlng, 17);
      setPosition(e.latlng);
    },
  });

  useEffect(() => {
    map.locate();
  }, [map]);

  return null;
}

const MapComponent = ({ setLatLng, onPositionChange }) => {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (position) {
      setLatLng(position);
      onPositionChange(position);
    }
  }, [position, setLatLng, onPositionChange]);

  return (
    <MapContainer center={[9.9312, 76.2673]} zoom={10} style={{ height: '400px', width: '80%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {position && <Marker position={position} />}
      <LocationMarker setPosition={setPosition} />
    </MapContainer>
  );
};

export default MapComponent;