import React from "react";

function Privacy() {


    return (
        <div className="holder">
            <div className="container">
                <div className="orders-header-container">
                    <h2 className="header-text">Privacy Policy</h2>
                </div>
                <div className="form">
                    <p>Effective Date: 6 June, 2024</p>
                    <p>At Printtt, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, 
                        and safeguard your information when you visit our website https://printtt.in, use our services, or make a purchase from us.
                    </p>
                    
                    <h4>1. Information We Collect</h4>
                    <p>We may collect information about you in a variety of ways, including:
                        <ul>
                            <li>Personal Data: Name, email address, phone number, billing address, and other contact details.</li>
                            <li>Order Information: Details related to your order, such as the products purchased, delivery address, and payment information.</li>
                            <li>Usage Data: Information about how you use our website and services, such as your IP address, browser type, access times, and referring website addresses.</li>
                        </ul>
                    </p>

                    <h4>2. How We Use Your Information</h4>
                    <p>We use the information we collect to:
                        <ul>
                            <li>Process and manage your orders.</li>
                            <li>Communicate with you about your orders, account, or other customer service needs.</li>
                            <li>Improve our website and services.</li>
                            <li>Comply with legal obligations.</li>
                        </ul>
                    </p>

                    <h4>3. Sharing Your Information</h4>
                    <p>
                        We do not sell, trade, or otherwise transfer your personal information to outside parties except 
                        to trusted third parties who assist us in operating our website, conducting our business, or servicing you, 
                        so long as those parties agree to keep this information confidential.
                    </p>

                    <h4>4. Security of Your Information</h4>
                    <p>
                        We use administrative, technical, and physical security measures to help protect your personal information. 
                        While we have taken reasonable steps to secure the personal information you provide to us, please be aware 
                        that despite our efforts, no security measures are perfect or impenetrable.
                    </p>

                    <h4>5. Your Choices</h4>
                    <p>
                        You may update or delete your personal information at any time by contacting us. You can also opt out of receiving 
                        marketing communications from us by following the unsubscribe instructions included in our emails.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Privacy;