import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../component/Button'

import config from '../config/BackendConfiguration';
import PostRequest from '../helper/PostRequest';
import { defaultToast, successToast, errorToast, clearToast } from '../helper/ToastMaster'
import { LoginContext } from '../helper/LoginContext';
import {LoaderContext} from '../helper/LoaderContext'

function Home() {

    const [lsu, setLsu] = useState(1); // 0 for login, 1 for sign-up
    const loginClick = () => {setLsu(0);}
    const signUpClick = () => {setLsu(1);}
    const { postRequest, responsePost, headersPost, errorPost } = PostRequest();
    const navigate = useNavigate();
    const {login} = useContext(LoginContext);
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const emailRef = useRef(null);
    const mobileRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const dobRef = useRef(null);
    const genderRef = useRef(null);
    const passRef = useRef(null);
    const confirmPassRef = useRef(null);

    const signUpAction = async () => {

        if(passRef.current.value != confirmPassRef.current.value) {
            alert("Passwords don't match");
        }

        const url = config.API_HOST + config.SIGNUP_URL;
        const body = {
            'email': emailRef.current.value,
            'mobile': mobileRef.current.value,
            'firstName': firstNameRef.current.value,
            'lastName': lastNameRef.current.value,
            'dob': dobRef.current.value,
            'gender': genderRef.current.value,
            'password': passRef.current.value,
        };
        const requestHeaders = { 'Content-Type': 'application/json' };
    
        defaultToast("Signing you up ...");
        showLoader();
        const response = await postRequest(url, body, requestHeaders);
        clearToast();
        if(200 == response.status){
            hideLoader();
            successToast("Sign up success!")
            setLsu(0);
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Sign up failed! " + "\n" + response.data.errorMessage);
        }
    }

    const loginAction = async () => {

        const url = config.API_HOST + config.LOGIN_URL;
        const body = {
            'email': emailRef.current.value,
            'password': passRef.current.value
        };

        showLoader();
    
        defaultToast("Logging in ...");
        const response = await postRequest(url, body, {});
        clearToast();
        if(200 == response.status){
            hideLoader();
            successToast("Welcome back, " + response.data.data.firstName + "!");
            login(response.data.data.userId, response.data.data.token, response.data.data.firstName);
            navigate('/home');
        } else {
            hideLoader();
            console.log(response.data.errorCode + " : " + response.data.errorMessage);
            errorToast("Login failed! " + "\n" + response.data.errorMessage);
        }
    }


    return (
    <div className="holder">
        <div className="header-container">
            <Button clazz={ lsu == 1 ? "button-selected button-fixed-width" : "button-unselected button-fixed-width" } onClick={signUpClick} label="Sign Up" />
            <Button clazz={ lsu == 0 ? "button-selected button-fixed-width" : "button-unselected button-fixed-width" } onClick={loginClick} label="Login" />
        </div>
        <div className="container form">
            <div className="form-group">
                <label className = "input-header">E-Mail: </label>
                <input className="input-reg" type="email" placeholder="john.doe@mail.com" ref={emailRef} required/>
            </div>
            <div className={"form-group"} style={{ display: lsu ? 'flex' : 'none' }}>
                <label className = "input-header">Mobile: </label>
                <input className="input-reg" type="mobile" placeholder="9876543210" ref={mobileRef} required />
            </div>
            <div className="form-group" style={{ display: lsu ? 'flex' : 'none' }}>
                <label className = "input-header">First Name: </label>
                <input className="input-reg" type="text" placeholder="John" ref={firstNameRef} required />
            </div>
            <div className="form-group" style={{ display: lsu ? 'flex' : 'none' }}>
                <label className = "input-header">Last Name: </label>
                <input className="input-reg" type="text" placeholder="Doe" ref={lastNameRef} required />
            </div>
            <div className="form-group" style={{ display: lsu ? 'flex' : 'none' }}>
                <label className = "input-header">Date of Birth: </label>
                <input className="input-reg" type="date" ref={dobRef} required />
            </div>
            <div className="form-group" style={{ display: lsu ? 'flex' : 'none' }}>
                <label className = "input-header">Gender: </label>
                <select className="input-reg" defaultValue="" ref={genderRef} required>
                    <option value="" disabled>Select an option</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">Other</option>
                </select>
            </div>
            <div className="form-group">
                <label className = "input-header">Password: </label>
                <input className="input-reg" type="password" ref={passRef} required />
            </div>
            <div className="form-group" style={{ display: lsu ? 'flex' : 'none' }}>
                <label className = "input-header">Confirm password: </label>
                <input className="input-reg" type="password" ref={confirmPassRef} required />
            </div>
            <div className="submit-container">
                { lsu == 1 && <Button clazz="button-unselected button-fixed-width" onClick={signUpAction} label="Sign Up" />}
                { lsu == 0 && <Button clazz="button-unselected button-fixed-width" onClick={loginAction} label="Login" />}
            </div>
        </div>

    </div>
    );
}

export default Home;